<template>
  <div ref="fileDropArea" @click="openFileUploader">
    <slot
      v-bind="{
        openUploader: openFileUploader,
        clearFiles: clearFiles,
      }"
    ></slot>
    <input
      ref="fileInput"
      :name="name"
      type="file"
      class="hidden"
      :multiple="multiple"
      :accept="accept"
      @input.stop="handleUpload"
    />
  </div>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    draggingClasses: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "*",
    },
    name: {
      type: String,
      default: "file",
    },
  },

  data() {
    return {
      files: null,
      dropArea: null,
    }
  },

  mounted() {
    this.dragAreaHandlerFunction = event => {
      event.preventDefault()

      if (this.draggingClasses) {
        if (["dragover", "dragenter"].includes(event.type)) {
          this.dropArea.classList.add(this.draggingClasses)
        }
        if (["dragleave", "drop"].includes(event.type)) {
          this.dropArea.classList.remove(this.draggingClasses)
        }
      }

      if (event.type === "drop") {
        let dt = event.dataTransfer
        let files = dt.files

        this.files = this.multiple ? files : files[0]
        this.$emit("input", this.files)
        this.$emit("upload", this.files)
      }
    }

    this.initializeDropArea()
  },

  methods: {
    openFileUploader() {
      this.$refs.fileInput.click()
    },
    initializeDropArea() {
      const dropArea = this.$refs.fileDropArea
      if (!dropArea) return
      this.dropArea = dropArea
      this.registerDropAreaListeners()
    },
    registerDropAreaListeners() {
      this.dropArea.addEventListener("dragenter", this.dragAreaHandlerFunction)
      this.dropArea.addEventListener("dragleave", this.dragAreaHandlerFunction)
      this.dropArea.addEventListener("dragover", this.dragAreaHandlerFunction)
      this.dropArea.addEventListener("drop", this.dragAreaHandlerFunction)
    },
    unregisterDropAreaListeners() {
      if (!this.dropArea) return

      this.dropArea.removeEventListener("dragenter", this.dragAreaHandlerFunction)
      this.dropArea.removeEventListener("dragleave", this.dragAreaHandlerFunction)
      this.dropArea.removeEventListener("dragover", this.dragAreaHandlerFunction)
      this.dropArea.removeEventListener("drop", this.dragAreaHandlerFunction)
      this.dropArea = null
    },
    clearFiles() {
      this.$refs.fileInput.value = ""
      this.files = null
      this.$emit("input", null)
    },
    handleUpload(e) {
      this.files = this.multiple ? e.target.files : e.target.files[0]
      this.$emit("input", this.files)
      this.$emit("upload", this.files)
    },
  },
}
</script>
